var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage'](_vm.lang.label))+" "+_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Section')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.title","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title'),"name":"title","fou":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.brief","rules":"","name":"The Brief"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Brief'),"name":"brief","fou":""},model:{value:(_vm.formData.brief),callback:function ($$v) {_vm.$set(_vm.formData, "brief", $$v)},expression:"formData.brief"}})]}}])})],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Content')))]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Training List')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.trainings),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.trainingtitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.trainings[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.trainings[listItemIndex], "title", $$v)},expression:"formData.trainings[listItemIndex].title"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.trainingdate","rules":"","name":"The Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'date_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Date')+' '+(listItemIndex+1)},model:{value:(_vm.formData.trainings[listItemIndex].date),callback:function ($$v) {_vm.$set(_vm.formData.trainings[listItemIndex], "date", $$v)},expression:"formData.trainings[listItemIndex].date"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.trainings, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.trainings, { title: '', date: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)]),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Experience List')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.experiences),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.experiencetitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.experiences[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.experiences[listItemIndex], "title", $$v)},expression:"formData.experiences[listItemIndex].title"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.experiencedate","rules":"","name":"The Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'date_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Date')+' '+(listItemIndex+1)},model:{value:(_vm.formData.experiences[listItemIndex].date),callback:function ($$v) {_vm.$set(_vm.formData.experiences[listItemIndex], "date", $$v)},expression:"formData.experiences[listItemIndex].date"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.experiences, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.experiences, { title: '', date: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Scientific Societies List')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.scientific_societies),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.scientificsocietietitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.scientific_societies[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.scientific_societies[listItemIndex], "title", $$v)},expression:"formData.scientific_societies[listItemIndex].title"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.scientific_societies, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.scientific_societies, {title: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)]),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Volunteering List')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.volunteering),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.volunteeringtitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.volunteering[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.volunteering[listItemIndex], "title", $$v)},expression:"formData.volunteering[listItemIndex].title"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.volunteering, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.volunteering, {title: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }