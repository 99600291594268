<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage'](lang.label) }} {{ $store.getters['languageMixin/getStringFromLanguage']('Section') }}</h5>


      <!--          Title & Order Council Number-->
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            vid="fr.title"
            rules=""
            name="The Title"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :error="failed ? errors[0]: null"
                      :label="$store.getters['languageMixin/getStringFromLanguage']('Title')"
                      name="title"
                      fou
                      v-model="formData.title">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <!--          Brief-->
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            vid="fr.brief"
            rules=""
            name="The Brief"
            v-slot="{ passed, failed,errors }">
            <fg-text type="text"
                      :error="failed ? errors[0]: null"
                      :label="$store.getters['languageMixin/getStringFromLanguage']('Brief')"
                      name="brief"
                      fou
                      v-model="formData.brief">
            </fg-text>
          </ValidationProvider>
        </div>
      </div>

      <!--          Content-->
      <div class="form-group">
        <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Content') }}</label>
        <editor
          v-model="formData.content"
          :api-key="editorKey"
          :init='editorConfig'
        />
      </div>

      <div class="row">
        <!--        Trainings list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Training List') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.trainings">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.trainingtitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.trainings[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.trainingdate"
                    rules=""
                    name="The Date"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'date_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Date')+' '+(listItemIndex+1)"
                              v-model="formData.trainings[listItemIndex].date">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.trainings, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.trainings, { title: '', date: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>
        <!--        Experience list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Experience List') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.experiences">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.experiencetitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.experiences[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.experiencedate"
                    rules=""
                    name="The Date"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'date_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Date')+' '+(listItemIndex+1)"
                              v-model="formData.experiences[listItemIndex].date">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.experiences, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.experiences, { title: '', date: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>
      </div>


      <div class="row">
        <!--            Scientific Societies list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Scientific Societies List') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.scientific_societies">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.scientificsocietietitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.scientific_societies[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.scientific_societies, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.scientific_societies, {title: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>

        <!--            Volunteering list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Volunteering List') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.volunteering">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.volunteeringtitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.volunteering[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.volunteering, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.volunteering, {title: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>
      </div>

    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import Editor from '@tinymce/tinymce-vue'
  import FormGroupTextArea from "@/components";

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    components: {
      FormGroupTextArea,
      [Select.name]: Select,
      [Option.name]: Option,
      'editor': Editor
    },
    props: {
      lang: {
        type: Object
      },
      editMode: {
        type: Boolean,
        default: false
      },
      isSubmit: {
        type: Boolean,
        default: false
      },
      formData:{
        type: Object,
        default() {
          return {
            title: "",
            brief:"",
            content:"",
            trainings: [],
            experiences: [],
            scientific_societies: [],
            volunteering: [],
          }
        }
      },
    },
    data () {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang.value, this.isSubmit)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      addNewItem(listItems, initObject) {
        listItems.push(initObject);
      },
      removeItem(listItems, index) {
        listItems.splice(index, 1);
      },
    }
  }
</script>
<style>
</style>
